import clsx from 'clsx';
import * as React from 'react';

function ImagePane({
  className,
  image,
  title,
  content,
  desktopImagePosition = 'left',
  desktopImageWidth = 'xl:w-2/3',
  desktopTitleWidth = 'xl:w-1/3',
  desktopTitleVerticalPosition = 'center',
  desktopClassName,
  breakpoint = 'xl',
}) {
  return (
    <div className={clsx(className)}>
      <div className={`${breakpoint}:hidden`}>
        <div>{title}</div>
        <div>{image}</div>
        <div>{content}</div>
      </div>
      <div className={clsx('hidden flex-row flex-nowrap items-stretch gap-16', `${breakpoint}:flex`, desktopClassName)}>
        <div
          className={clsx('flex justify-center items-center', desktopImageWidth, desktopImagePosition === 'right' && 'order-2')}
        >
          {image}
        </div>
        <div
          className={clsx(
            desktopTitleWidth,
            'flex flex-col items-start',
            `justify-${desktopTitleVerticalPosition}`,
          )}
        >
          {title}
          {content}
        </div>
      </div>
    </div>
  );
}

export default ImagePane;
