import React from 'react';

export default function FancyTitle({
  text, subtitle, className, tag = 'div', fontSize = 'lg:text-5xl', marginBottom = 'mb-20', textAlign = 'start', lineHeight = 'leading-normal', styleOverrides = '',
}) {
  const Tag = tag;
  return (
    <div className={`${className || ''} ${textAlign} ${marginBottom}`}>
      <Tag
        className={`
        inline-block
        relative
        text-3xl
        ${fontSize}
        ${lineHeight}
        font-bold
        fancy-title
        ${styleOverrides}
        w-full
      `}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      { subtitle && (
      <h3 className={`text-latori-green font-monospace font-bold uppercase text-xl pt-0 lg:pt-3 ${textAlign}`}>{ subtitle }</h3>
      )}
    </div>
  );
}
